import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import { TableHeader, TableRow, TableActions, TableRowActions, SearchFrame, SearchForm, SearchTable, FormContent } from '@front/squirtle'
import { Grid, Typography } from '@mui/material'
import { Form, Header, Row, ButtonGlobalAction, ButtonRowAction } from './'

import Callbacks from './callbacks'

const Title = ({ type }) => <Grid item xs={12}>
  <Typography variant={'h5'} sx={{ fontSize: 18 }}>{I18n.t(`day.${type}.label`, { count: -1 })}</Typography>
</Grid>

const CustomDay = ({ record_id, type, model_name, key_id, _searchTable = {} }) => {
  const { tableProps: _searchTable_tableProps, _searchTable_rest } = _searchTable
  const filterToForm = useCallback(Callbacks.filterToFormHandler(), [])
  const formToFilter = useCallback(Callbacks.formToFilterHandler(record_id), [record_id])
  const filterToState = useCallback(Callbacks.filterToStateHandler(record_id), [record_id])
  const formToRecord = useCallback(Callbacks.formToRecordHandler(record_id), [record_id])
  const recordToForm = useCallback(Callbacks.recordToFormHandler(), [])

  return (
    <Grid container item xs={12} rowSpacing={3} justifyContent={'center'}>
      <Title type={type} />
      <Grid item xs={11}>
        <SearchFrame
          model_name={model_name}
          populate={['day']}
          allowCreate={false}
        >
          <SearchForm
            filterToForm={filterToForm}
            formToFilter={formToFilter}
            filterToState={filterToState}
            submitOnMount={!!record_id}
            disableButton
          >
            <FormContent><Form /></FormContent>
          </SearchForm>
          <SearchTable
            formProps={{
              recordToForm,
              formToRecord
            }}
            tableProps={{
              onRowClick: null,
              enableToolbar: false,
              enablePagination: false,
              enableRowActions: true,
              enableRowSelection: false,
              enableGlobalActions: true,
              enableGlobalActionsPlacement: 'bottom',
              disableCancelRowLabel: true,
              ..._searchTable_tableProps
            }}
            {..._searchTable_rest}
          >
            <TableHeader><Header /></TableHeader>
            <TableRow><Row /></TableRow>
            <TableRowActions><ButtonRowAction icon='delete' model_name={model_name} key_id={key_id} /></TableRowActions>
            <TableActions><ButtonGlobalAction type={type} /></TableActions>
          </SearchTable>
        </SearchFrame >
      </Grid>
    </Grid>
  )
}

export default React.memo(CustomDay)
