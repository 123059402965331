import React from 'react'
import { hooks, I18n } from '@front/volcanion'

import { Box, Grid, Typography } from '@mui/material'
import { Loader } from '@front/squirtle'

import ListTag from '@abra/elements/tags/List'
import Actions from '@abra/elements/Button/Form'

import OrderUtils from '@front/volcanion/utils/order'

const headerDefaultProps = {
  sx: {
    display: 'flex',
    fontSize: '1.8vw',
    fontWeight: 300,
    letterSpacing: 2
  }
}
const defaultTypographyProps = {
  sx: {
    mr: 1,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    letterSpacing: 'inherit'
  },
  variant: 'h5'
}

const OrderHeader = ({ order_id, enableList = true, enableActions = false, headerLabelProps, ...props }) => {
  const [order, { isLoading }] = hooks.useModel('order', [order_id], { single: true, populate: ['channel', 'assigned_transport', 'list', 'loads'] })
  const order_status = hooks.useOrderStatus(order, { translated: true })
  const hasTransport = _.get(order, 'assigned_transport')
  const load_type = order?.loads[0]?.type
  const type = hooks.useLoadType(order, { translated: true })
  const transport_status = hooks.useTransportStatus(_.get(order, 'assigned_transport'), { translated: true, load_type })

  return <>
    <Loader isLoading={isLoading}>
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-end'>
        <Grid item xs={12} hidden={!order}>
          <Box {..._.merge({}, headerDefaultProps, headerLabelProps)}>
            <Typography {...defaultTypographyProps} >{I18n.t('order.label.with_arg', { type })}</Typography>
            <Typography   {..._.merge({}, defaultTypographyProps, { sx: { color: OrderUtils.getColorTheme(type), fontWeight: 600 } })}  >
              {_.get(order, 'order_client_id')}
            </Typography>
            <Typography {...defaultTypographyProps}> - </Typography>
            <Typography {...defaultTypographyProps}>{I18n.t(`order.${_.get(order, 'schedule_type')}.short`)}</Typography>
            <Typography {...defaultTypographyProps}>{I18n.t(!!_.get(order, 'confirmed') ? 'registered.one' : 'registered.devis')}</Typography>
            <Typography {...defaultTypographyProps}>{_.get(order, 'channel.name')}</Typography>
            <Typography {...defaultTypographyProps}> - </Typography>
            <Typography {..._.merge({}, defaultTypographyProps, { color: hooks.useOrderStatusColor(order), fontWeight: 400 })}>
              {order_status}
            </Typography>
            <Typography {..._.merge({}, defaultTypographyProps, { sx: { display: !!hasTransport ? 'block' : 'none' } })}>
              |
            </Typography>
            <Typography {..._.merge({}, defaultTypographyProps, { color: hooks.useTransportStatusColor(_.get(order, 'assigned_transport')), hidden: !hasTransport, fontWeight: 400 })}>
              {transport_status}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} hidden={!enableActions}>
          <Actions {...props} />
        </Grid>
        <Grid item xs={12} hidden={!_.get(order, 'list') || !enableList}>
          <Box sx={{ display: 'flex' }}>
            <Typography  {..._.merge({}, defaultTypographyProps, { sx: { fontSize: 'subtitle.fontSize', color: 'text.label', opacity: 0.5 } })}>{I18n.t('list.associated_order')}</Typography>
            <Typography  {..._.merge({}, defaultTypographyProps, { sx: { fontSize: 'subtitle.fontSize' } })}><ListTag list={_.get(order, 'list')} /></Typography>
          </Box>
        </Grid>
      </Grid >
    </Loader >
  </>
}

export default React.memo(OrderHeader)
