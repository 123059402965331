import React from 'react'
import { useTheme } from '@mui/styles'

import { TableSortLabel } from '@mui/material'
import { TextHeader } from '@abra/elements/table/headers'


const SortHeader = ({ handleClick, enableSortHeader, sortDirection, ..._textHeaderProps }) =>
  <TableSortLabel
    active
    direction={_.lowerCase(sortDirection)}
    onClick={handleClick}
    sx={{
      '.MuiTableSortLabel-icon': {
        color: !!enableSortHeader && `${_.get(useTheme(), 'palette.primary.main')} !important`
      }
    }}
  >
    <TextHeader  {..._textHeaderProps} />
  </TableSortLabel>

export default React.memo(SortHeader)
