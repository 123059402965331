import React from 'react'

import SuspensionTitleComponent from './Title'
import SuspensionPopupComponent from './Popup'

import withSuspension from './withSuspension'

const SuspensionTitleForm = React.memo(withSuspension(SuspensionTitleComponent))
const SuspensionPopupForm = React.memo(withSuspension(SuspensionPopupComponent))

export {
  SuspensionTitleComponent, SuspensionPopupComponent,
  SuspensionTitleForm, SuspensionPopupForm,
}
