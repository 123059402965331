import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const form_name = hooks.useFormName()
  const [labelKey] = hooks.useFormState('model_name')
  const [record_client_id] = hooks.useGlobalFormState(form_name, 'title')

  const mergedProps = {
    isManagingRecord,
    labelKey,
    record_client_id,
    form_name
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
