import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => ({ modifyAllowed, actions, path }) => {
  const navigate = hooks.useNavigate()
  const onSubmit = hooks.useFormFunction('requestSubmit')
  const [isReadOnly, setReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')

  const goBack = useCallback(Callbacks.goBackHandler(navigate.path), [navigate, path])
  const onEditChange = useCallback(Callbacks.onEditChangeHandler(setReadOnly, isReadOnly), [setReadOnly, isReadOnly])

  const mergedProps = {
    actions,
    isManagingRecord,
    isReadOnly,
    modifyAllowed,
    onEditChange,
    goBack,
    onSubmit
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
