import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'
import Callbacks from './callbacks'

const withContainer = Component => ({ order_id, driver_id }) => {
  const [order] = hooks.useModel('order', [order_id], { populate: ['vehicle_opts', 'driver_opts'], single: true })
  const [driver] = hooks.useModel('user', [driver_id], {
    single: true,
    populate: ['auth', 'options', 'vehicle.options', 'blacklisted_customers', 'blacklisted_services'],
  })

  const handleVerifyDriver = useCallback(Callbacks.handleVerifyDriverHandler(), [])
  const errors = useMemo(() => handleVerifyDriver(driver, order), [HookUtils.getRecordsWatcher(order, driver)])

  const mergedProps = {
    errors
  }

  return <Component {...mergedProps} />

}

export default withContainer
