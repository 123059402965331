
class Callbacks {
  static filterToFormHandler() {
    return function filterToForm(filter) {
      return {
        ...filter
      }
    }
  }
  static filterToStateHandler(contract) {
    return function filterToState(state) {
      return {
        contract
      }
    }
  }
  static formToFilterHandler(schedule) {
    return function formToFilter(values, extra, meta, state) {
      return {
        schedule
      }
    }
  }
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        ..._.get(record, 'day'),
        ...record
      }
    }
  }

  static formToRecordHandler(record_id) {
    return function formToRecord(day, extra, meta, state) {
      if (extra?.data?.result_type === 'success') {
        return {
          day,
          schedule: extra?.data?.result?.schedule_id || record_id
        }
      } else throw extra?.data?.result
    }
  }
}

export default Callbacks
