import React from 'react'

import { Typography, Link } from '@mui/material/'

const LinkCell = ({ value, href = '#', sxProps = {}, ...props }) =>
  <Typography sx={{ textAlign: 'center', ...sxProps }}>
    {!!value
      ? <Link href={href} target={'_blank'}>
        {value}
      </Link>
      : '-'
    }
  </Typography>

export default React.memo(LinkCell)
