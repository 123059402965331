import React, { useCallback, useState } from 'react'
import { hooks, I18n } from '@front/volcanion'
import { Grid, Box, Typography, Chip, Button } from '@mui/material'
import moment from 'moment'
import { FormatUtils, TimeUtils } from '@front/volcanion/utils'

const EventItem = ({ label, value }) => {
  const CustomLabel = <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
    <Typography fontSize={13}>{label}</Typography>
    <Typography fontSize={13} fontWeight="bold">{value || '-'}</Typography>
  </Box>
  return <Chip label={CustomLabel} size="small" />
}
const EventModelItem = ({ label, event }) => {
  const { value, model_name, path } = event
  const [display_value] = hooks.useModel(model_name, _.flatten([value]), { mutator: path })
  return <EventItem value={display_value?.join(', ')} label={label} />
}

const EventAudioItem = ({ label, event }) => {
  const { value, expiredAt } = event
  const [audio, setAudio] = useState()
  const [downloadAudio] = hooks.useVonageRecord(value)
  const onClick = useCallback(async () => downloadAudio(), [downloadAudio])
  const isExpired = moment.utc(expiredAt).isBefore(moment.utc())

  return (
    <Grid>
      {!!isExpired && <EventItem label={label} value={I18n.t('event.keys.expired')} />}
      {!audio && !isExpired && (
        <Button
          size='small'
          color='primary'
          variant='contained'
          onClick={async () => setAudio(await onClick())}
        >
          {label}
        </Button>
      )}
      {!!audio && !isExpired && (
        <audio controls="controls">
          <source src={audio} type="audio/mp3" />
        </audio>
      )}
    </Grid>
  )
}

const AssociatedInfoCell = ({ event }) => {
  const label = event?.label || I18n.t(`event.keys.${event?.key}`)
  switch (event?.type) {
    case 'model':
      return <EventModelItem label={label} event={event} />
    case 'audio':
      return <EventAudioItem label={label} event={event} />
    case 'distance':
      return <EventItem label={label} value={FormatUtils.distance(event?.value)} />
    case 'duration':
      return <EventItem label={label} value={TimeUtils.secondsToTime(event?.value * 60, 'HH[h]mm')} />
    case 'translation':
      return <EventItem label={label} value={!!event?.value ? I18n.t(`event.keys.${event?.value}`) : '-'} />
    case 'datetime':
      return <EventItem label={label} value={TimeUtils.formatDateBackToFront(event?.value, event?.format)} />
    case 'float':
      return <EventItem label={label} value={_.compact([event?.value?.toFixed(event.format || 2), event.unit]).join('')} />
    default:
      return <EventItem label={label} value={_.compact([event?.value, event?.suffix || event?.unit]).join('')} />
  }
}

export default AssociatedInfoCell
