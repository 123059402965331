import React, { useCallback } from 'react'

import { Box, IconButton } from '@mui/material'
import { SwapHoriz, Merge, PhoneEnabled } from '@mui/icons-material'


import Callbacks from './callbacks'


const IVRMiddle = ({ contactFunction, ...props }, context) => {

  const toggleActiveConnection = useCallback(Callbacks.toggleActiveConnectionGenerator(contactFunction), [contactFunction])
  const conferenceConnection = useCallback(Callbacks.conferenceConnectionGenerator(contactFunction), [contactFunction])

  return (
    <Box m={1} flexShrink={0}>
      <IconButton aria-label="ivr" onClick={toggleActiveConnection} >
        <Box color='icon'><SwapHoriz /></Box>
      </IconButton>
      <IconButton aria-label="ivr" onClick={conferenceConnection} >
        <Box color='icon'><Merge /></Box>
      </IconButton>
      <IconButton aria-label="ivr" style={{ color: 'red' }} onClick={() => contactFunction.getAgentConnection().destroy()} >
        <Box color='icon'><PhoneEnabled /></Box>
      </IconButton>
      {'|'}
    </Box>
  )

}

// Export
export default React.memo(IVRMiddle)
