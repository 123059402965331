import React, { useEffect, useMemo } from 'react'
import { I18n } from '@front/volcanion'

import { Box, Stepper, Step, StepLabel, Typography } from '@mui/material'

import { Check, Close } from '@mui/icons-material'

import { makeStyles } from '@mui/styles'

const styles = theme => ({
  alternativeLabel: {
    margin: '0 !important',
  }
})

const useStyles = makeStyles(styles)

const StepIconComponent = ({ error, size, ...props }) =>
  <Box sx={{
    backgroundColor: !!error ? 'red' : 'green',
    fontSize: 13,
    color: 'white',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 1,
    width: size,
    height: size
  }}>
    {!!error ? <Close fontSize={'inherit'} /> : <Check fontSize={'inherit'} />}
  </Box>

const PasswordComplexity = props => {

  const {
    value,
    disableTitle = true,
    onScoreChange = _.noop,
    iconSize = 22,
    fontSize
  } = props
  const classes = useStyles(props)

  const {
    titleSize = 'h6.fontSize',
    textSize = 'subtitle.fontSize',
  } = fontSize || {}

  const validators = useMemo(() => ([
    { label: I18n.t('password.rules.length'), error: !value || value.length < 7 },
    { label: I18n.t('password.rules.upper'), error: !value || !value.match(/[A-Z]/g) },
    { label: I18n.t('password.rules.lower'), error: !value || !value.match(/[a-z]/g) },
    { label: I18n.t('password.rules.number'), error: !value || !value.match(/\d/g) }
  ]), [value])

  const score = validators.length - _.filter(validators, 'error').length

  const complexityLevel = useMemo(() => {
    switch (score) {
      case 0:
      case 1:
        return I18n.t('password.complexity.weak')
      case 2:
      case 3:
        return I18n.t('password.complexity.good')
      case 4:
        return I18n.t('password.complexity.strong')
    }
  }, [score])

  const complexityColor = useMemo(() => {
    switch (score) {
      case 0:
      case 1:
        return 'red'
      case 2:
      case 3:
        return 'orange'
      case 4:
        return 'green'
    }
  }, [score])

  useEffect(() => {
    onScoreChange(score)
  }, [score])

  return (
    <Box sx={{ display: !!value ? 'block' : 'none' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }} >
        {!disableTitle && <Typography sx={{ mr: 1, color: 'text.label', fontSize: titleSize, letterSpacing: 1.5 }}>
          {I18n.t('password.complexity.level')}
        </Typography>}
        <Typography sx={{ color: complexityColor, fontSize: titleSize, letterSpacing: 1.5 }}>{complexityLevel} </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        <Stepper alternativeLabel orientation='horizontal'>
          {_.map(validators, rule =>
            <Step key={rule.label} completed active={!rule.error}>
              <StepLabel classes={classes} StepIconComponent={() => <StepIconComponent error={!!rule.error} size={iconSize} />}>
                <Typography sx={{ fontSize: textSize, color: 'text.label', fontWeight: 200 }}>
                  {rule.label}
                </Typography>
              </StepLabel>
            </Step>
          )}
        </Stepper>
      </Box>
    </Box >
  )
}

export default React.memo(PasswordComplexity)
