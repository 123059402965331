import React from 'react'
import { I18n } from '@front/volcanion'
import FormatUtils from '@front/squirtle/utils/format'
import { TextCell } from '..'


const StatusCell = ({ value, ...rest }) => {
  const status = FormatUtils.getStatus(value, ['active', 'deleted', 'suspendedUntil', 'suspendedSince'])
  const statusColor = FormatUtils.getStatusColor(status)
  return (
    <TextCell value={I18n.t(`status.${status}`)} sx={{ color: statusColor, fontWeight: 'bold' }}  {...rest} />
  )
}


export default StatusCell
