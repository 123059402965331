import React from 'react'
import { makeStyles } from '@mui/styles';
import styles from './styles'
import _ from 'lodash';
import Tag from '.'
import { hooks } from '@front/volcanion'

const useStyles = makeStyles(styles)

const ListTag = ({ list: input_list }) => {
  const [list] = hooks.useModel('list', [_.get(input_list, 'list_id')], { single: true })
  return (
    <Tag model_name={'list'} id={_.get(list, 'list_id')}>
      {_.get(list, 'list_client_id')}
    </Tag>
  )
}

export default React.memo(ListTag)
