import React, { useEffect, useCallback, useState, useMemo } from 'react'

import { Box, Grid, Typography, Icon, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'

import {
  HeadsetMic,
  ExitToApp,
  Mic,
  MicOff,
  CheckCircle,
  Dialpad,
  Contacts,
  Timer,
  AccountBox
} from '@mui/icons-material'

import { MenuSelectorComponent, TextPhoneInputComponent } from '@front/squirtle'

import { I18n } from '@front/volcanion'


import IncomingCallComponent from './components/incomingCall'
import CallComponent from './components/call'
import MiddleComponent from './components/middle'

import Callbacks from './callbacks'
import Memos from './memos'

import "amazon-connect-streams";
import styles from './styles'
const useStyles = makeStyles(styles)


const IvrBar = (props, context) => {

  const {
    defaultLanguage,
    navigate,
    CppURL
  } = props

  const classes = useStyles(props)

  const [agentStatus, setAgentStatus] = useState()
  const [incomingAgentStatus, setIncomingAgentStatus] = useState()
  const [agentFunction, setAgentFunction] = useState(() => { })
  const [contactFunction, setContactFunction] = useState(() => { })
  const [isMute, setIsMute] = useState(false)
  const [inputPhone, setInputPhone] = useState()
  const [agentContacts, setAgentContacts] = useState([])
  const [togglePhone, setTogglePhone] = useState(false)
  const [toggleContact, setToggleContact] = useState(false)
  const [selectedEndPoint, setSelectedEndPoint] = useState(null)
  const [breakTimer, setBreakTimer] = useState()

  const agentStatusType = useMemo(() => _.get(agentStatus, 'type'), [_.get(agentStatus, 'type')])
  const agentStatusName = useMemo(() => _.get(agentStatus, 'name'), [_.get(agentStatus, 'name')])
  const iconColor = useMemo(() => Memos.getIconColor(agentStatusType), [agentStatusType])
  const agent_info = useMemo(() => Memos.getAgentInfo(agentFunction), [agentFunction])
  const contact_info = useMemo(() => Memos.getContactInfo(contactFunction), [contactFunction])
  const endpoint = useMemo(() => Memos.getEndpoint(selectedEndPoint, inputPhone, toggleContact), [selectedEndPoint, inputPhone, toggleContact])
  const isInitialConnection = useMemo(() => Memos.isInitialConnection(contactFunction), [contactFunction])
  const isThirdPartyConnection = useMemo(() => Memos.isThirdPartyConnection(contactFunction), [contactFunction])
  const display_phone = useMemo(() => Memos.displayPhoneComponent(agentStatusType, agentStatusName), [agentStatusType, agentStatusName])

  const onAgentStatusChange = useCallback(Callbacks.onAgentStatusChangeGenerator(setAgentStatus, setTogglePhone), [])
  const onMute = useCallback(Callbacks.onMuteGenerator(setIsMute), [])
  const handleMute = useCallback(Callbacks.handleMuteGenerator(agentFunction, isMute), [agentFunction, isMute])
  const handleConnect = useCallback(
    Callbacks.handleConnectGenerator(endpoint, contactFunction, agentFunction, setTogglePhone, setToggleContact),
    [endpoint, contactFunction, agentFunction]
  )
  const menuOnChange = useCallback(Callbacks.menuOnChangeGenerator(agentFunction), [agentFunction])
  const onConnected = useCallback(Callbacks.onConnectedGenerator(setTogglePhone, setToggleContact), [])
  const onCallEnded = useCallback(Callbacks.onCallEndedGenerator(setAgentContacts, setTogglePhone, setToggleContact, setIncomingAgentStatus, setSelectedEndPoint), [])
  const onEnqueuedNextState = useCallback(Callbacks.onEnqueuedNextStateGenerator(setIncomingAgentStatus), [])
  const timerBreak = useCallback(Callbacks.timerBreakGenerator(agentFunction, setBreakTimer), [agentFunction])
  const eventBusSubscribe = useCallback(Callbacks.eventBusSubscribeGenerator(), [])
  const agentEvents = useCallback(
    Callbacks.agentEventsGenerator(setAgentFunction, onAgentStatusChange, onMute, onEnqueuedNextState, eventBusSubscribe, setAgentStatus, setIncomingAgentStatus),
    [onAgentStatusChange, onMute, onEnqueuedNextState, eventBusSubscribe]
  )
  const contactEvents = useCallback(
    Callbacks.contactEventsGenerator(setContactFunction, onConnected, onCallEnded),
    [onConnected, onCallEnded]
  )
  const init = useCallback(Callbacks.initGenerator(CppURL, contactEvents, agentEvents), [CppURL])
  const getAgentContacts = useCallback(Callbacks.getAgentContactsGenerator(agentFunction, setAgentContacts), [agentFunction])


  useEffect(() => {
    return init()
  }, [])

  useEffect(() => {
    if (agentStatusType === 'not_routable') {
      const timer = setInterval(timerBreak, 1000)
      return () => clearInterval(timer)
    }
    return () => { }
  }, [agentStatusType])

  useEffect(() => {
    getAgentContacts(_.get(contact_info, 'queue.queueARN'))
  }, [_.get(contact_info, 'queue.queueARN')])

  useEffect(() => {
    if (!!togglePhone)
      setToggleContact(false)
  }, [togglePhone])

  useEffect(() => {
    if (!!toggleContact)
      setTogglePhone(false)
  }, [toggleContact])

  return (
    <Grid container spacing={1} alignItems='center' sx={{ height: 1 }}>
      <Grid item xs>
        {!!_.get(agent_info, 'name') &&
          <Typography color="inherit" className={classes.title} variant="subtitle1" gutterBottom>
            <Box mr={2}>  {I18n.t('ivr.title', { name: _.get(agent_info, 'name') })}</Box>
            <Icon aria-label="ivr" style={{ color: iconColor, margin: '8px' }}>
              <Box color='icon'>{<HeadsetMic />}</Box>
            </Icon>
            <Box m={1} flexShrink={0}>
              <MenuSelectorComponent
                name='agent_status'
                options={_.map(_.uniqBy(_.compact(_.flatten([_.get(agent_info, 'status_list'), [agentStatus]])), 'name'), (state) => ({
                  label: state.type === 'system' || state.type === 'error' ? I18n.t(`ivr.status.${state.name}`) : state.name,
                  value: state.name
                }))}
                onChange={(value) => menuOnChange(value, _.get(agentStatus, 'type') === 'system')}
                value={_.get(incomingAgentStatus, 'name') || _.get(agentStatus, 'name')}
                valueDecoratorProps={{
                  color: 'white'
                }}
              />
            </Box>
            {_.get(agentStatus, 'type') === 'not_routable' && <Box m={1}><Timer style={{ color: iconColor }} /></Box>}
            {_.get(agentStatus, 'type') === 'not_routable' && <Box m={1}>{breakTimer}</Box>}
            {_.toLower(_.get(agentStatus, 'name')) === 'busy' &&
              <IconButton aria-label="ivr" style={{ color: isMute ? 'red' : 'white' }} onClick={handleMute} >
                <Box color='icon'>{isMute ? <MicOff /> : <Mic />}</Box>
              </IconButton>
            }
            {!_.get(agent_info, 'auto_accept') && agentStatusName === 'PendingBusy' &&
              <IncomingCallComponent callerPhone={_.get(contact_info, 'phones[0]')} contactFunction={contactFunction} />
            }
            {
              isInitialConnection && display_phone &&
              <CallComponent callerPhone={_.get(contact_info, 'phones[0]')} contactFunction={contactFunction} />
            }
            {isInitialConnection && isThirdPartyConnection &&
              <MiddleComponent contactFunction={contactFunction} />
            }
            {isThirdPartyConnection &&
              <CallComponent callerPhone={_.get(contact_info, 'phones[1]')} contactFunction={contactFunction} isThirdConnection={true} />
            }
            {(_.toLower(_.get(agentStatus, 'type')) === 'offline' || _.toLower(_.get(agentStatus, 'type')) === 'system') &&

              <IconButton onClick={() => setTogglePhone(!togglePhone)}>
                <Box color='icon'> <Dialpad /> </Box>
              </IconButton>
            }
            {!_.isEmpty(agentContacts) && <IconButton onClick={() => setToggleContact(!toggleContact)}>
              <Box color='icon'> <Contacts /> </Box>
            </IconButton>}

            {togglePhone &&
              <Box>
                <TextPhoneInputComponent
                  defaultCountry={defaultLanguage}
                  value={inputPhone}
                  onChange={setInputPhone}
                  availableCountries={agent_info?.available_countries}
                />
              </Box>
            }
            {toggleContact && <Box>
              <MenuSelectorComponent
                name='agent_contact'
                options={agentContacts}
                labelKeys={['name']}
                selectKeys={[]}
                matchKeys={['name', 'endpointARN', 'endpointId', 'type']}
                onChange={(value) => setSelectedEndPoint(value)}
                value={selectedEndPoint}
              />
            </Box>}
            {(togglePhone || toggleContact) && <IconButton aria-label="ivr" onClick={handleConnect} >
              <Box color='icon'><CheckCircle /></Box>
            </IconButton>}
            <IconButton aria-label="ivr" onClick={() => navigate('/order/create', { state: { phone: _.get(contact_info, 'phones[0]') } })} >
              <Box color='icon'><ExitToApp /></Box>
            </IconButton>

          </Typography>}
        {!!CppURL && !_.get(agent_info, 'name') && <AccountBox aria-label="ivr" onClick={() => window.open(`${CppURL}/connect/ccp-v2#`, '_blank')} ></AccountBox>}
        <div id="ccpContainer" hidden >
        </div>
      </Grid>
    </Grid>
  )
}

// Export
export default React.memo(IvrBar)
