class Memos {
  static getAgentInfo(agent) {
    if (!agent) return null
    const config = agent.getConfiguration()
    const name = config.name
    const available_countries = config.dialableCountries
    const status_list = config.agentStates
    const auto_accept = config.softphoneAutoAccept

    const incoming_status = agent.getNextState()

    return {
      name,
      available_countries,
      status_list,
      auto_accept,
      incoming_status
    }
  }

  static getIconColor(agentStatusType) {
    switch (agentStatusType) {
      case 'routable':
        return 'green'
      case 'not_routable':
      case 'system':
        return 'orange'
      case 'offline':
      default:
        return 'red'
    }
  }

  static getContactInfo(contact) {
    if (!contact) return null
    const queue = contact.getQueue()
    const phones = []
    if (contact.getActiveInitialConnection()) {
      const endpoint = contact.getActiveInitialConnection().getEndpoint()
      if (!!endpoint.phoneNumber)
        phones.push(endpoint.phoneNumber)
      else
        phones.push(endpoint.name)
    }
    if (contact.getSingleActiveThirdPartyConnection()) {
      const endpoint = contact.getSingleActiveThirdPartyConnection().getEndpoint()
      if (_.isEmpty(phones))
        phones.push('')
      if (!!endpoint.phoneNumber)
        phones.push(endpoint.phoneNumber)
      else
        phones.push(endpoint.name)
    }
    return {
      queue,
      phones
    }
  }

  static getEndpoint(selectedEndPoint, inputPhone, toggleContact) {
    if (toggleContact && !_.isEmpty(selectedEndPoint))
      return selectedEndPoint
    else if (!!inputPhone)
      return connect.Endpoint.byPhoneNumber(inputPhone)
    return null
  }

  static isInitialConnection(contact) {
    if (!contact || _.isEmpty(contact))
      return false
    try {
      return !!contact.getActiveInitialConnection() && !!contact.getAgentConnection().isActive()
    }
    catch (err) {
      return false
    }
  }

  static isThirdPartyConnection(contact) {
    if (!contact || _.isEmpty(contact))
      return false
    return !!contact.getSingleActiveThirdPartyConnection() && !!contact.getAgentConnection().isActive()
  }

  static displayPhoneComponent(agentStatusType, agentStatusName) {
    return agentStatusType === 'system' && (agentStatusName === 'Busy' || agentStatusName === 'CallingCustomer')
  }

}

export default Memos
