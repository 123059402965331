import React, { useMemo, useState, useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, SpeedDial, ClickAwayListener, SpeedDialAction, Icon } from '@mui/material/'

const SpeedDialTooltipOpen = ({ actions = [], onSubmit, isReadOnly, modifyAllowed = true, goBack, onEditChange }) => {
  const [open, setOpen] = useState(false);

  const onSpeedDialClick = useCallback(e =>
    _.includes(['click', 'mouseenter'], e?.type) && setOpen(!open)
    , [open, setOpen])

  const onClickAway = useCallback(e =>
    !!open && onSpeedDialClick()
    , [onSpeedDialClick, open])

  const default_actions = useMemo(() => _.compact([
    { name: I18n.t('action.back'), iconName: 'arrow_back', iconColor: 'error', onClick: goBack, hidden: false },
    { name: I18n.t('action.edit'), iconName: !!isReadOnly ? 'edit' : 'edit_off', iconColor: !!isReadOnly ? 'primary' : 'error', onClick: onEditChange, hidden: !modifyAllowed },
    { name: I18n.t('action.save'), iconName: 'save', iconColor: 'success', onClick: onSubmit, hidden: !!isReadOnly }
  ]), [onSubmit, isReadOnly, modifyAllowed])

  const options = _.filter(_.flatten([default_actions, actions]), (elem) => !elem?.hidden)
  return <>
    <ClickAwayListener onClickAway={onClickAway}>
      <SpeedDial
        ariaLabel='SpeedDial basic example'
        sx={{ position: 'absolute', top: 70, right: 16, zIndex: 500 }}
        onClose={onSpeedDialClick}
        onOpen={onSpeedDialClick}
        open={open}
        direction={'down'}
        icon={<Icon color='white'>{'settings'}</Icon>}
      >
        {_.map(options, ({ action, iconName, iconColor, name, ...rest }, key) => (
          <SpeedDialAction
            key={key}
            tooltipTitle={<Typography sx={{ cursor: 'pointer', '&:hover': { fontWeight: 'bold' } }}>{name}</Typography>}
            tooltipOpen={!!name}
            sx={{
              '.MuiSpeedDialAction-staticTooltipLabel': {
                width: 'max-content'
              }
            }}
            icon={<Icon color={iconColor || 'primary'}>{iconName}</Icon>}
            {...rest}
          />
        ))}
      </SpeedDial>
    </ClickAwayListener>
  </>
}

export default SpeedDialTooltipOpen