import React from 'react'
import { I18n } from '@front/volcanion'

import { TableCell } from '@mui/material'
import { TextHeader } from '@abra/elements/table/headers/'

const Header = props => <>
  <TableCell><TextHeader label={I18n.t('date.label', { count: 1 })} sx={{ fontSize: 15 }} /></TableCell>
  <TableCell><TextHeader label={I18n.t('hour.start')} sx={{ fontSize: 15 }} /></TableCell>
  <TableCell><TextHeader label={I18n.t('hour.end')} sx={{ fontSize: 15 }} /></TableCell>
  <TableCell><TextHeader label={I18n.t('repeat.year')} sx={{ fontSize: 15 }} /></TableCell>
</>

export default React.memo(Header)
