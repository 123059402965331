import { I18n } from '@front/volcanion'

class Callbacks {
  static handleTelephonyHandler(openDialog, telephony_profile) {
    return function handlerTelephony() {
      if (!!_.get(telephony_profile, 'telephonyprofile_id'))
        return openDialog('confirm_unpair_telephony_profile', {
          title: I18n.t('telephony.label', { count: 1 }),
          description: I18n.t('telephony.toast.unpair.description', { name: _.get(telephony_profile, 'name') })
        })
      return openDialog('telephony_profile_pair', {
        title: I18n.t('telephony.label', { count: 1 }),
        description: I18n.t('telephony.toast.pair.description')
      })
    }
  }

  static onConfirmTelephonyPairHandler(openDialog, telephonyProfilePair) {
    return async function onConfirmTelephonyPair(values, extra, meta, state) {
      const { telephonyprofile_id } = values || {}
      try {
        await telephonyProfilePair({ telephonyprofile_id })
      }
      catch (err) {
        if (_.get(err, 'code') === 'PROFILE_ALREADY_USED') {
          return openDialog('confirm_force_pairing_telephony_profile', {
            title: I18n.t('telephony.toast.force.title'),
            description: I18n.t('telephony.toast.force.description')
          }, { telephonyprofile_id })
        }
        throw err
      }
    }
  }
  static onSignOutHandler(clear, telephonyProfileUnpair, CPP_URL) {
    return async function onSignOut() {
      telephonyProfileUnpair().catch(console.warn)
      clear()

      if (!!CPP_URL) {
        const eventBus = connect.core.getEventBus()
        eventBus.trigger(connect.EventType.TERMINATE)
        fetch(`${CPP_URL}/connect/logout`, { credentials: 'include', mode: 'no-cors' }).catch(console.warn)
      }
    }
  }
  static onOpenPasswordChangePopupHandler(openDialog) {
    return function onOpenPasswordChangeDialog() {
      openDialog('password_change')
    }
  }
  static submitPasswordChangePopupHandler(openNotification, changePassword, setPasswordFormValues) {
    return async function submitPasswordChangePopup(values) {
      const { password_old, password_new } = values
      try {
        await changePassword({ new_password: password_new, old_password: password_old })
        openNotification(I18n.t('password.change.success'), { variant: 'success' })
      } catch (err) {
        setPasswordFormValues([
          { field: 'password_old', meta: { error: true, field_error: I18n.t('password.change.error.incorrect') } }
        ])
        openNotification(I18n.t('password.change.error.incorrect'), { variant: 'error' })
        throw err
      }
    }
  }
}

export default Callbacks

