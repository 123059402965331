import React from 'react'

import { IconButton, Typography, Icon, List, ListItem } from '@mui/material'

const SubMenu = ({ items, handleOnClick, selectedItem, mode }) => {
  const listColor = mode === 'dark' ? 'white' : 'primary.dark'
  return (
    <List component='div' sx={{ pointerEvents: 'auto', p: 0 }} dense>
      {
        _.map(items, (item, key) => (
          <ListItem
            id={item.id}
            key={item.id}
            onClick={() => handleOnClick(item.route)}
            sx={{
              cursor: 'pointer',
              color: item.id === selectedItem ? 'primary.light' : listColor,
              fontWeight: item.id === selectedItem ? 600 : 400,
              '&:hover': { fontWeight: 600, color: 'primary.light' }
            }}
            divider
            dense
          >
            <IconButton sx={{ color: 'inherit' }} disableRipple>
              <Icon> {item.icon}</Icon>
            </IconButton>
            <Typography variant={'h6'} sx={{ letterSpacing: 1, fontWeight: 'inherit', color: 'inherit' }}>
              {item.text}
            </Typography>
          </ListItem>
        ))
      }
    </List >
  )
}

export default React.memo(SubMenu)
