import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import FormatUtils from '@front/squirtle/utils/format'

const withContainer = Component => ({
  label,
  model_name,
  record_id,
  form_name,
  suspendedStateKey,
}) => {
  const [, { openDialog }] = hooks.useDialogs()
  const [isReadOnly] = hooks.useGlobalFormState(form_name, 'isReadOnly')
  const [, setParentSuspended] = hooks.useGlobalFormState(form_name, suspendedStateKey)

  const [record] = hooks.useModel(model_name, [record_id], { single: true })
  const [modifyRecord] = hooks.useModelFunction(model_name, 'modify')
  const { primary_key } = hooks.useModelSchema(model_name)

  const formattedSuspension = FormatUtils.formatSuspended(_.pick(record, ['suspendedSince', 'suspendedUntil', 'motive']))
  const onConfirm = useCallback(async () => {
    await modifyRecord({
      filter: { [primary_key]: record_id },
      updates: { status: { name: 'active' } },
    })
    setParentSuspended({ motive: null, suspendedSince: null, suspendedUntil: null })
  }, [modifyRecord, primary_key, record_id, setParentSuspended])

  const mergedProps = {
    label,
    isReadOnly,
    formattedSuspension,
    openDialog,
    onConfirm
  }

  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
