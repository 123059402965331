import React from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const navigate = hooks.useNavigate()
  const [language] = hooks.useSetting('country_code')

  const mergedProps = {
    navigate,
    defaultLanguage: _.toLower(language),
  }
  return <Component {...mergedProps} {...props} />

}

export default withContainer
