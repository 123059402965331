import React from 'react'
import { I18n } from '@front/volcanion'

import { Button, Box, Grid } from '@mui/material'

const Actions = ({
  enableGoBack = true,
  enableSubmit = true,
  enableCancel = true,
  isFormMounted,
  isManagingRecord,
  onEdit,
  onSave,
  onCancel,
  onBack,
  buttonProps,
  children,
  ...props
}) =>
  <Box>
    <Grid container>
      <Grid item xs>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box hidden={!!isFormMounted || !isManagingRecord} sx={{ p: 1 }}>
            {children}
          </Box>
          <Box hidden={!!isFormMounted || !isManagingRecord} sx={{ p: 1 }}>
            <Button variant='contained' {...buttonProps} onClick={onEdit}>
              {I18n.t('action.edit')}
            </Button>
          </Box>
          <Box hidden={!isFormMounted || !enableSubmit} sx={{ p: 1 }}>
            <Button variant='contained' {...buttonProps} onClick={onSave}>
              {I18n.t('action.save')}
            </Button>
          </Box>
          <Box hidden={!!isFormMounted || !enableGoBack} sx={{ p: 1 }}>
            <Button variant='contained' {...buttonProps} onClick={onBack}>
              {I18n.t('action.back')}
            </Button>
          </Box>
          <Box hidden={!isFormMounted || !enableCancel} sx={{ p: 1 }}>
            <Button variant='contained' {...buttonProps} onClick={onCancel}>
              {I18n.t('action.cancel')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>


export default React.memo(Actions)
