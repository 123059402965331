import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => ({ row, key_id, model_name, ...props }) => {
  const id = _.get(row, key_id)
  const [deleteRecord] = hooks.useModelFunction(model_name, 'delete')
  const onDelete = useCallback(() => deleteRecord({ [key_id]: id }), [id])

  const mergedProps = {
    row,
    onDelete
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer
