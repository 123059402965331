import React from 'react'
import { Badge, Box, Button, Typography } from '@mui/material'


import FormatUtils from '@front/squirtle/utils/format'
import { hooks, I18n } from '@front/volcanion'
import { Loader } from '@front/squirtle'

const IncidentHeader = ({ event_id, approve, refuse, badgeContent, badgeColor, badgeClick = _.noop, ...props }) => {
  const [incident, { isLoading }] = hooks.useModel('event', [event_id], { single: true })
  const incident_label = _.compact(['incident', _.get(incident, 'name')]).join('.')

  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Loader isLoading={isLoading}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Badge
              onClick={e => e?.target?.nodeName === 'SPAN' && badgeClick()}
              color={badgeColor}
              badgeContent={badgeContent}
              sx={{ '.MuiBadge-badge': { fontSize: 22, height: 40, width: 40, borderRadius: '30px' } }}
            >
              <Typography sx={{
                alignItems: 'center',
                fontSize: 'h5.fontSize',
                color: 'white',
                width: 'fit-content',
                p: 1,
                m: 1,
                boxShadow: 6,
                letterSpacing: 3,
                borderRadius: 1,
                bgcolor: 'primary.main'
              }}>
                {`${FormatUtils.formatDateBackToFront(_.get(incident, 'producedAt'), '[Le] DD/MM/YYYY [à] HH:mm')}- ${I18n.t(incident_label, { count: 1 })}`}
              </Typography>
            </Badge>
          </Box>
          <Box sx={{ p: 3, }}>
            <Button variant="outlined" color='success' onClick={approve} sx={{ mr: 2 }}>{I18n.t('action.approve')}</Button>
            <Button variant="outlined" color='error' onClick={refuse}>{I18n.t('action.refuse')}</Button>
          </Box>
        </Box>

      </Loader>
    </Box >
  )
}

export default React.memo(IncidentHeader)
