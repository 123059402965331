import _ from 'lodash'
import moment from 'moment'

class IVRCallbacks {

  static initGenerator(CppURL, contactEvents, agentEvents) {
    return function init() {
      const containerDiv = document.getElementById("ccpContainer")
      if (!CppURL) return _.noop
      connect.core.initCCP(containerDiv, {
        ccpUrl: `${CppURL}/connect/ccp-v2#`,            // REQUIRED
        loginPopup: true,               // optional, defaults to `true`
        loginPopupAutoClose: true,      // optional, defaults to `false`
        loginOptions: {                 // optional, if provided opens login in new window
          autoClose: true,              // optional, defaults to `false`
          height: 600,                  // optional, defaults to 578
          width: 400,                   // optional, defaults to 433
          top: 0,                       // optional, defaults to 0
          left: 0                       // optional, defaults to 0
        },
        region: "eu-central-1",         // REQUIRED for `CHAT`, optional otherwise
        softphone: {                    // optional, defaults below apply if not provided
          allowFramedSoftphone: true,   // optional, defaults to false
          disableRingtone: false,       // optional, defaults to false
          // ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
        },
        pageOptions: {                  // optional
          enableAudioDeviceSettings: false, // optional, defaults to 'false'
          enablePhoneTypeSettings: true // optional, defaults to 'true'
        },
        storageAccess: {
          canRequest: false,
          mode: 'default'
        }
      })
      connect.core.onInitialized(() => {
        connect.contact(contactEvents)
        connect.agent(agentEvents)
      })
      return () => connect.core.terminate()
    }
  }

  static onAgentStatusChangeGenerator(setAgentStatus, setTogglePhone) {
    return function onAgentStatusChange({ agent }) {
      const status = agent.getState()
      setAgentStatus(status)
      setTogglePhone(false)
    }
  }

  static onMuteGenerator(setIsMute) {
    return function onMute({ muted = false }) {
      setIsMute(muted)
    }
  }

  static handleMuteGenerator(agentFunction, isMute) {
    return function handleMute() {
      if (isMute)
        agentFunction.unmute()
      else
        agentFunction.mute()
    }
  }

  static handleConnectGenerator(endpoint, contactFunction, agentFunction, setTogglePhone, setToggleContact) {
    return async function handleConnect() {
      if (!!endpoint) {
        const contacts = agentFunction.getContacts()
        if (!_.isEmpty(contacts)) {
          contactFunction.addConnection(endpoint, {
            success: () => {
              setTogglePhone(false)
              setToggleContact(false)
            }
          })
        }
        else {
          agentFunction.connect(endpoint)
        }
      }
    }
  }

  static menuOnChangeGenerator(agentFunction) {
    return async function menuOnChange(value, enqueueNextState = false) {
      const status_list = agentFunction.getAgentStates()
      const state = _.find(status_list, (status) => status.name === value)
      agentFunction.setState(state, {}, { enqueueNextState })
    }
  }

  static onConnectedGenerator(setTogglePhone, setToggleContact) {
    return async function onConnected(contact) {
      setTogglePhone(false)
      setToggleContact(false)
    }
  }

  static onCallEndedGenerator(setAgentContacts, setTogglePhone, setToggleContact, setIncomingAgentStatus, setSelectedEndPoint) {
    return async function onCallEnded(contact) {
      setAgentContacts([])
      setTogglePhone(false)
      setToggleContact(false)
      setIncomingAgentStatus(null)
      setSelectedEndPoint(null)
    }
  }

  static timerBreakGenerator(agentFunction, setBreakTimer) {
    return async function timerBreak() {
      const duration = agentFunction.getStateDuration()
      const new_time = moment.utc(duration).format('mm:ss')
      setBreakTimer(new_time)
    }
  }

  static onEnqueuedNextStateGenerator(setIncomingAgentStatus) {
    return async function onEnqueuedNextStateCall(agent) {
      const next_status = agent.getNextState()
      setIncomingAgentStatus(next_status)
    }
  }

  static getAgentContactsGenerator(agentFunction, setAgentContacts) {
    return async function getAgentContacts(queueARN) {
      if (!!agentFunction && !!queueARN)
        agentFunction.getEndpoints(
          [queueARN],
          {
            success: function (data) {
              const endpoints = data.endpoints
              setAgentContacts(endpoints)
            },
            failure: function (err) {
              setAgentContacts([])
            }
          }
        )
      setAgentContacts([])
    }
  }


  static eventBusSubscribeGenerator() {
    return async function eventBusSubscribe(agent) {
      const eventBus = connect.core.getEventBus()

      eventBus.subscribe(connect.EventType.TERMINATE, () => {
        const status_list = agent.getAgentStates()
        const state = _.find(status_list, (status) => status.type === 'offline')
        agent.setState(state)
      })
    }
  }

  static agentEventsGenerator(setAgentFunction, onAgentStatusChange, onMute, onEnqueuedNextState, eventBusSubscribe, setAgentStatus, setIncomingAgentStatus) {
    return async function agentEvents(agent) {
      const status = agent.getState()
      const next_state = agent.getNextState()
      setAgentStatus(status)
      setIncomingAgentStatus(next_state)
      setAgentFunction(agent)
      agent.onStateChange(onAgentStatusChange)
      agent.onMuteToggle(onMute)
      agent.onEnqueuedNextState(onEnqueuedNextState)
      eventBusSubscribe(agent)
    }
  }

  static contactEventsGenerator(setContactFunction, onConnected, onCallEnded) {
    return async function contactEvents(contact) {
      setContactFunction(contact)
      contact.onConnected(onConnected)
      contact.onRefresh((c) => setContactFunction(c))
      contact.onEnded(onCallEnded)
      contact.onError(onCallEnded)
    }
  }
}

export default IVRCallbacks
