import React from 'react'

import { IconButton, Typography, Icon, Box, Collapse, ListItem } from '@mui/material'
import SubMenu from '../../submenu'

const CollapseMenu = ({ item, handleMenuToggle, enabled, handleOnClick, selected, selectedSubitem }) =>
  <>
    <ListItem
      id={item.id}
      key={item.id}
      onClick={handleMenuToggle}
      sx={{
        color: enabled ? 'primary.main' : 'icon.default',
        fontWeight: enabled ? 600 : 200,
        '&:hover': { color: 'primary.main', fontWeight: 600 }
      }}
      divider
    >
      <IconButton sx={{ color: 'inherit' }} disableRipple>
        <Icon> {item.icon}</Icon>
      </IconButton>
      <Typography sx={{ letterSpacing: 1, fontWeight: 'inherit', color: 'inherit' }}>
        -{item.text}
      </Typography>
    </ListItem>
    <Collapse
      in={enabled}
      timeout="auto"
      unmountOnExit
    >
      <Box sx={{ bgcolor: enabled ? 'white' : 'background.menu' }}>
        <SubMenu key={item.id} items={item.subitems} handleOnClick={handleOnClick} selectedItem={selectedSubitem} />
      </Box>
    </Collapse>
  </>
export default React.memo(CollapseMenu)
