import React, { useCallback } from 'react'

import { Box, IconButton } from '@mui/material'
import { PermPhoneMsg, PhoneDisabled } from '@mui/icons-material'

import Callbacks from './callbacks'

const IVRIncomingCall = props => {
  const {
    contactFunction,
    callerPhone
  } = props

  const handleCall = useCallback(Callbacks.handleCallGenerator(contactFunction), [contactFunction])

  return (
    <Box m={1} flexShrink={0}>
      <IconButton aria-label="ivr" style={{ color: 'white' }} onClick={() => handleCall(true)} >
        <Box color='icon'><PermPhoneMsg /></Box>
      </IconButton>
      <span>{callerPhone}</span>
      <IconButton aria-label="ivr" onClick={() => handleCall()} >
        <Box color='icon'><PhoneDisabled /></Box>
      </IconButton>
      {'|'}
    </Box>
  )

}

export default React.memo(IVRIncomingCall)
