import React from 'react'

import { TableCell } from '@mui/material'
import { TimeField, DateField, SingleOptionSelectorField } from '@front/squirtle'

const Row = props => <>
  <TableCell size={'small'} align={'center'} sx={{ width: 165 }}>
    <DateField
      name={'date'}
      required
      inputProps={{ style: { textAlign: 'center' } }}
      inputFormat='DD/MM/YYYY'
    />
  </TableCell>
  <TableCell size={'small'} align={'center'} sx={{ width: 165 }}>
    <TimeField
      name={'start_time'}
      inputProps={{ style: { textAlign: 'right' } }}
    />
  </TableCell>
  <TableCell size={'small'} align={'center'} sx={{ width: 165 }}>
    <TimeField
      name={'end_time'}
      inputProps={{ style: { textAlign: 'right' } }}
    />
  </TableCell>
  <TableCell size={'small'} align={'center'}>
    <SingleOptionSelectorField name={'repeat_year'} />
  </TableCell>
</>

export default React.memo(Row)
