import React, { useMemo } from 'react'

import { Typography, Box } from '@mui/material'

import GeoUtils from '@front/volcanion/utils/geo'

const AddressCell = ({ source: inputSource, destination: inputDestination, ...props }, context) => {
  const source = useMemo(() => GeoUtils.format(inputSource), [inputSource])
  const destination = useMemo(() => GeoUtils.format(inputDestination), [inputDestination])

  return (
    <Box alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Box sx={{ borderRadius: '50%', height: '7px', width: '7px', bgcolor: 'green', mr: 1, mb: '3px', boxShadow: 6 }} />
        <Typography> {source} </Typography>
      </Box>
      <Box display={!inputDestination ? 'none' : 'flex'} alignItems='center'>
        <Box sx={{ borderRadius: '50%', height: '7px', width: '7px', bgcolor: 'red', mr: 1, mb: '3px', boxShadow: 6 }} />
        <Typography> {destination} </Typography>
      </Box>
    </Box>
  )
}

export default AddressCell
