import React, { useCallback } from 'react'


const withContainer = Component => props => {
  const triggerEventHandler = useCallback(key_obj => window.dispatchEvent(new KeyboardEvent('keydown', key_obj)), [])
  const handleHotkey = useCallback(key => {
    if (_.isEmpty(key)) return
    else if (_.isString(key)) {
      triggerEventHandler({ key })
    }
    else {
      triggerEventHandler({ key: _.last(key), ctrlKey: _.head(key) === 'Ctrl', altKey: _.head(key) === 'Alt' })
    }
  }, [])

  const mergedProps = {
    handleHotkey
  }

  return <Component {...mergedProps} {...props} />
}

export default withContainer
