import React from 'react'
import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material'
import { I18n } from '@front/volcanion'

const TypographyError = ({ error }) => (
  <Typography>
    {I18n.t(error.message)}
    {!_.isEmpty(error.options) ? <Box component="span" fontWeight={600}>{` ${error.options}`}</Box> : ''}
  </Typography>
)

const DispatchErrors = ({ errors }) => {
  return (
    <Grid container xs={12}>
      <Box sx={{ flex: 1, color: 'red', bgcolor: 'rgba(226, 24, 24, 0.2)', border: 1, borderColor: 'red' }} hidden={_.isEmpty(errors)}>
        <List dense sx={{ listStyle: "disc", pl: 4 }}>
          {_.map(errors, (error, index) => (
            <ListItem dense disablePadding key={index} sx={{ display: "list-item" }}>
              <ListItemText primary={<TypographyError error={error} />} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Grid>
  )
}

export default React.memo(DispatchErrors)
