


class Callbacks {
  static goBackHandler(navigate, path) {
    return function goBack() {
      navigate(!!path ? path : -1)
      return null
    }
  }
  static onEditChangeHandler(setReadOnly, isReadOnly) {
    return function onEdit(value) {
      setReadOnly(_.isBoolean(value) ? value : !isReadOnly)
      return null
    }
  }

}

export default Callbacks
