import moment from 'moment'

class IVRCallbacks {
  static handleHoldGenerator(connectionFunction, setAgentHold, agentHold) {
    return function handleHold() {
      if (_.get(agentHold, 'status')) {
        connectionFunction.resume()
        setAgentHold({ status: false, color: 'white' })
      }
      else {
        connectionFunction.hold()
        setAgentHold({ status: true, color: 'red' })
      }
    }
  }

  static timerCallGenerator(connectionFunction, setContactDuration) {
    return function timerCall() {
      const duration = connectionFunction.getStateDuration()
      const new_time = moment.utc(duration).format('mm:ss')
      setContactDuration(new_time)
    }
  }

  static toggleActiveConnectionGenerator(contactFunction) {
    return function toggleActiveConnection() {
      contactFunction.toggleActiveConnections({
        success: () => console.log('toggle connection success'),
        failure: (err) => console.log('toggle connection failed', { err })
      })
    }
  }

  static conferenceConnectionGenerator(contactFunction) {
    return function conferenceConnection() {
      contactFunction.conferenceConnections({
        success: () => console.log('conference connection success'),
        failure: (err) => console.log('conference connection failed', { err })
      })
    }
  }

  static handleCallGenerator(contactFunction) {
    return function handleCall(accept) {
      if (accept)
        contactFunction.accept()
      else
        contactFunction.reject()
    }
  }

}

export default IVRCallbacks
