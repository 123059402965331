import React, { useMemo, useCallback } from 'react'

const withContainer = Component => ({ sortField, handleSort, sortState, defaultSort = 'ASC', ...rest }) => {
  const [currentSortField, currentSortDirection] = useMemo(() => !!sortState
    ? _.split(_.head(sortState), ' ')
    : [null, null], [_.join(_.compact(sortState), ' ')])

  const enableSortHeader = useMemo(() => sortField === currentSortField, [sortField, currentSortField])
  const headerSortField = useMemo(() => !!enableSortHeader ? currentSortField : sortField, [enableSortHeader, currentSortField, currentSortField, sortField])
  const sortDirection = useMemo(() => !!enableSortHeader ? currentSortDirection : defaultSort, [enableSortHeader, currentSortDirection, defaultSort])
  const reverseHeaderDirection = useMemo(() => sortDirection === 'DESC' ? 'ASC' : 'DESC', [sortDirection])

  const handleClick = useCallback(() => handleSort([`${headerSortField} ${reverseHeaderDirection}`]), [handleSort, headerSortField, reverseHeaderDirection]);

  const mergedProps = {
    handleClick,
    enableSortHeader,
    sortDirection,
  }

  return (
    <Component {...mergedProps} {...rest} />
  )
}
export default withContainer
