import React from 'react'
import { I18n } from '@front/volcanion'

import { ModelMenuSelectorComponent, TextComponent } from '@front/squirtle'
import { Grid } from '@mui/material'

const Row1 = ({ user }) =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextComponent
        label={I18n.t('user.id')}
        value={_.get(user, 'info.user_client_id')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('name.label.full')}
        value={_.get(user, 'name')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('status.label', { count: 1 })}
        value={I18n.t(`vehicle.status.${_.get(user, 'vehicle.status') || 'none'}`)}
        disabled
        emptyValue='-'
      />
    </Grid>
  </Grid>

const Row2 = ({ user }) =>
  <Grid container item xs={12}>
    <Grid item xs>
      <TextComponent
        label={I18n.t('vehicle.model', { count: 1 })}
        value={_.get(user, 'vehicle.model')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('vehicle.brand', { count: 1 })}
        value={_.get(user, 'vehicle.brand')}
        disabled
        emptyValue='-'
      />
    </Grid>
    <Grid item xs>
      <TextComponent
        label={I18n.t('vehicle.plaque')}
        value={_.get(user, 'vehicle.plaque')}
        disabled
        emptyValue='-'
      />
    </Grid>
  </Grid>

const Row3 = ({ user }) =>
  <Grid container item xs={12}>
    <Grid item xs={12}>
      <ModelMenuSelectorComponent
        label={I18n.t('options.vehicleoption.label', { count: 1 })}
        model_name={'vehicleoption'}
        labelKeys={['name_translated']}
        value={_.map(_.get(user, 'vehicle.options'), 'vehicle_option_id')}
        multiple
        disabled
      />
    </Grid>
    <Grid item xs={12}>
      <ModelMenuSelectorComponent
        label={I18n.t('options.useroption.label', { count: 1 })}
        model_name={'useroption'}
        labelKeys={['name_translated']}
        value={_.map(_.get(user, 'options'), 'user_option_id')}
        multiple
        disabled
      />
    </Grid>
  </Grid>


const userComponent = props =>
  <Grid container>
    <Row1 {...props} />
    <Row2 {...props} />
    <Row3 {...props} />
  </Grid>

export default userComponent
