import React, { useCallback } from 'react'
import FormatUtils from '@front/squirtle/utils/format'
import { hooks } from '@front/volcanion'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'

const withContainer = Component => ({
  model_name,
  record_id,
  form_name,
  suspendedStateKey,
  statusFieldKey
}) => {
  const [, setParentSuspended] = hooks.useGlobalFormState(form_name, suspendedStateKey)
  const setFormValues = hooks.useGlobalFormChange(form_name)

  const [motive] = hooks.useGlobalFieldValues('suspended_dialog_form', ['motive'])
  const [record, recordState] = hooks.useModel(model_name, [record_id], { single: true })

  const prevStatus = FormatUtils.getStatus(_.pick(record, ['active', 'deleted', 'suspendedUntil', 'suspendedSince']))
  const count_motive = _.size(motive)

  const initialValues = {
    motive: record?.motive,
    suspendedSince: record?.suspendedSince,
    suspendedUntil: record?.suspendedUntil
  }

  const onSubmit = useCallback(async (values) => setParentSuspended(values), [setParentSuspended])
  const onCancel = useCallback(() => setFormValues([{ field: statusFieldKey, value: prevStatus }]), [setFormValues, prevStatus])

  const mergedProps = {
    initialValues,
    count_motive,
    onSubmit,
    onCancel,
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([recordState])}>
      <Component {...mergedProps} />
    </Loader>
  )
}
export default withContainer
