import _ from 'lodash'
import version from './version.json'

const info = {
  locale: 'fr',
  version_number: _.get(version, 'number'),
  type: 'web'
}


export default info
