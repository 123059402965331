import React, { useCallback, useState, useEffect } from 'react'
import { hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const setPasswordFormValues = hooks.useGlobalFormChange('password_change')
  const { openNotification } = hooks.useNotification()
  const [, changePassword] = hooks.useAuthFunction('changePassword')
  const [, { openDialog }] = hooks.useDialogs()
  const [, clear] = hooks.useAuthFunction('clear')
  const [active_user] = hooks.useActiveUser({ populate: ['auth'] })
  const user_id = _.get(active_user, 'user_id')
  const user_name = _.get(active_user, 'auth.username')

  const { EXTERNAL_PHONE_SERVICE, CPP_URL } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE', 'CPP_URL'])
  const [telephonyProfileUnpair] = hooks.useModelFunction('telephonyprofile', 'unpair')
  const [telephonyProfilePair] = hooks.useModelFunction('telephonyprofile', 'pair')
  const [[telephony_profile], searchTelephonyProfile] = hooks.useModelSearch('telephonyprofile', 'get', {
    forced_filter: { operator: user_id },
    validate_filter: useCallback(() => !!user_id, [user_id]),
    watchers: [user_id]
  })
  const telephony_profile_name = _.get(telephony_profile, 'name')
  const display_telephony = EXTERNAL_PHONE_SERVICE === 'orange'

  const [mode, setMode] = hooks.useGlobalState('mode')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => setAnchorEl(event.currentTarget), [setAnchorEl])
  const handleClose = useCallback(() => setAnchorEl(null), [setAnchorEl])
  const handleClickDarkMode = useCallback(() => setMode(mode === 'dark' ? 'light' : 'dark'), [mode, setMode])

  const handleTelephony = useCallback(Callbacks.handleTelephonyHandler(openDialog, telephony_profile), [openDialog, telephony_profile])
  const onConfirmTelephonyPair = useCallback(Callbacks.onConfirmTelephonyPairHandler(openDialog, telephonyProfilePair), [openDialog, telephonyProfilePair])
  const onSignOut = useCallback(Callbacks.onSignOutHandler(clear, telephonyProfileUnpair, CPP_URL), [clear, telephonyProfileUnpair, CPP_URL])
  const onOpenPasswordChangePopup = useCallback(Callbacks.onOpenPasswordChangePopupHandler(openDialog), [openDialog])
  const submitPasswordChangePopupHandler = useCallback(Callbacks.submitPasswordChangePopupHandler(openNotification, changePassword, setPasswordFormValues), [openNotification, changePassword, setPasswordFormValues])

  useEffect(() => {
    !!user_id && searchTelephonyProfile()
  }, [user_id])

  const mergedProps = {
    mode,
    display_telephony,
    user_name,
    telephony_profile_name,
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleClickDarkMode,
    handleTelephony,
    onConfirmTelephonyPair,
    onSignOut,
    onOpenPasswordChangePopup,
    submitPasswordChangePopupHandler
  }
  return <Component {...mergedProps} {...props} />

}

export default withContainer
