import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { I18n } from '@front/volcanion'
import { ConfirmationDialog } from '@front/squirtle'

const Title = ({
  label,
  isReadOnly,
  formattedSuspension,
  onConfirm,
  openDialog,
}) => (
  <>
    <ConfirmationDialog
      name={'cancel_suspend'}
      onConfirm={onConfirm}
    />
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box style={{ display: 'flex', alignItems: 'baseline', gap: 16 }}>
        <Typography variant='h5'>{label}</Typography>
        <Typography variant='subtitle' color='error' fontWeight={600}>{formattedSuspension}</Typography>
      </Box>

      <Box style={{ display: formattedSuspension ? 'flex' : 'none', alignItems: 'center', gap: 8 }}>
        {!isReadOnly && (
          <Button size='small' variant='outlined' onClick={() => openDialog('suspended')}>
            {I18n.t('suspend.stop.modify')}
          </Button>
        )}
        <Button size='small' variant='outlined' onClick={() => openDialog('cancel_suspend', { title: I18n.t('suspend.stop.suspend'), description: I18n.t('suspend.stop.confirm') })}>
          {I18n.t('suspend.stop.suspend')}
        </Button>
      </Box>
    </Box>
  </>
)

export default Title
