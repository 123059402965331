import React, { useEffect } from 'react'
import { hooks } from '@front/volcanion'
const withContainer = Component => props => {
  const [mode] = hooks.useGlobalState('mode')
  const navigate = hooks.useNavigate()
  const current_path = hooks.usePathname()
  const [authorized_pages] = hooks.useModelSearch('page', 'getAuthorized', { default_limit: -1, initial_filter: {} })
  const [page_categories] = hooks.useModelSearch('pagecategory', 'get', { default_limit: -1, initial_filter: {} })
  const [isDrawerOpen, setDrawer] = hooks.useGlobalState('isDrawerOpen')
  const current_page = _.find(authorized_pages, (page) => !_.isEmpty(_.trimEnd(current_path, '/').match(new RegExp(`^${_.trimEnd(page.matcher, '/')}$`))))
  const current_page_name = current_page?.name_translated
  const menu_pages = _.filter(authorized_pages, ['menu', true])
  const [welcome_user_name] = hooks.useSetting('welcome_user_name')
  const filtered_categories = _.filter(page_categories, (category) => !!_.find(menu_pages, (page) => page.category === category.pagecategory_id))
  const sorted_catagories = _.orderBy(filtered_categories, ['index'])
  const CLIENT_COMMERCIAL_NAME = hooks.useRelayConstant('CLIENT_COMMERCIAL_NAME')

  const items = _.map(sorted_catagories, (category) => {
    const category_pages = _.filter(menu_pages, (page) => page.category === category.pagecategory_id)
    return {
      id: category.pagecategory_id,
      text: category.name_translated,
      icon: category.icon,
      subitems: _.map(_.orderBy(category_pages, ['index']), (category_page) => ({
        id: category_page.page_id,
        text: category_page.name_translated,
        route: category_page.path,
        icon: category_page.icon
      }))
    }
  })

  useEffect(() => {
    document.title = _.compact([current_page_name, CLIENT_COMMERCIAL_NAME]).join(' | ')
    return () => {
      document.title = _.compact([CLIENT_COMMERCIAL_NAME]).join('')
    }
  }, [CLIENT_COMMERCIAL_NAME, current_page_name])

  const mergedProps = {
    mode,
    welcome_user_name,
    isDrawerOpen,
    setDrawer,
    selectedItem: _.get(current_page, 'category'),
    selectedSubitem: _.get(current_page, 'page_id'),
    items,
    navigate
  }
  return <Component {...mergedProps} {...props} />

}

export default withContainer
