import React from 'react'
import { hooks } from "@front/volcanion"

const withSuspension = (Component) => ({
  label,
  record_id,
  model_name = 'userauth',
  suspendedStateKey = 'suspended',
  statusFieldKey = 'status'
}) => {
  const form_name = hooks.useFormName()

  const mergedProps = {
    label,
    model_name,
    record_id,
    form_name,
    suspendedStateKey,
    statusFieldKey,
  }

  return (
    <Component {...mergedProps} />
  )
}


export default withSuspension
