import React from 'react'
import { I18n } from '@front/volcanion'

import { FormDialog, DialogContent, ConfirmationDialog, ModelAutocompleteField } from '@front/squirtle'

import PasswordChange from './Popup/PasswordChange'

const MenuComponent = ({
  telephonyProfileUnpair,
  onConfirmTelephonyPair,
  submitPasswordChangePopupHandler,
  ...props
}) =>
  <>
    <ConfirmationDialog
      name={'confirm_unpair_telephony_profile'}
      onConfirm={telephonyProfileUnpair}
      yesLabel={I18n.t('action.logout')}
      noLabel={I18n.t('action.cancel')}
    />
    <ConfirmationDialog
      name={'confirm_force_pairing_telephony_profile'}
      onConfirm={async (state) => { await telephonyProfilePair({ telephonyprofile_id: _.get(state, 'telephonyprofile_id'), force: true }) }}
      yesLabel={I18n.t('telephony.toast.force.yes')}
    />
    <FormDialog
      name={'telephony_profile_pair'}
      formProps={{
        onSubmit: onConfirmTelephonyPair
      }}
    >
      <DialogContent>
        <ModelAutocompleteField
          name={'telephonyprofile_id'}
          label={I18n.t('telephonyprofile.label', { count: -1 })}
          model_name={'telephonyprofile'}
          searchMode={'search'}
          searchQuery={'startsWith'}
          selectKeys={['telephonyprofile_id']}
          autocompleteKey={'name'}
          config={{
            sort: ['name ASC'],
            populate: ['operator.info'],
            forced_filter: {
              active: true
            },
            mutator: telephonyprofile => {
              const dispo_label = !!_.get(telephonyprofile, 'operator') ? I18n.t('telephony.toast.pair.unavailable') : I18n.t('telephony.toast.pair.available')
              const operator_label = _.join(_.compact([_.get(telephonyprofile, 'operator.info.first_name'), _.get(telephonyprofile, 'operator.info.last_name')]), ' ')
              return _.merge({}, {
                label: _.join(_.compact([_.get(telephonyprofile, 'name'), _.join(_.compact([dispo_label, operator_label]), ' - ')]), ' / ')
              }, telephonyprofile)
            }
          }}
          required
          selectFirst={false}
          selectSingleOption
        />
      </DialogContent>
    </FormDialog>
    <FormDialog
      name={'password_change'}
      formProps={{
        onSubmit: submitPasswordChangePopupHandler
      }}
    >
      <DialogContent>
        <PasswordChange />
      </DialogContent>
    </FormDialog>
  </>
export default React.memo(MenuComponent)
