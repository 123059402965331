const styles = theme => ({
  error: {
    color: `${theme.palette.red} !important`,
    borderColor: `${theme.palette.red} !important`
  },
  warning: {
    color: `${theme.palette.orange} !important`,
    borderColor: `${theme.palette.orange} !important`
  },
  success: {
    color: `${theme.palette.green} !important`,
    borderColor: `${theme.palette.green} !important`
  }
})

export default styles
