import React from 'react'

import { Typography, Box, Icon } from '@mui/material'

const TextHeader = ({ label, icon, IconProps = {}, iconDirection = 'left', _sx, ...rest }) => {
  return <Box sx={{ display: 'flex', justifyContent: 'center', ..._sx }}>
    {iconDirection === 'left' && !!icon && <Icon sx={{ mr: 1 }} {...IconProps}>{icon}</ Icon>}
    <Typography color='primary' fontSize={15} {...rest}>{label}</Typography>
    {iconDirection === 'right' && !!icon && <Icon sx={{ ml: 1 }} {...IconProps}>{icon}</ Icon>}
  </Box >
}

export default TextHeader