import React, { useEffect, useCallback, useState, useMemo } from 'react'


import { Box, IconButton } from '@mui/material'
import { PhoneDisabled, PhonePaused } from '@mui/icons-material'

import Callbacks from './callbacks'

const IVRCall = (props, context) => {
  const {
    callerPhone,
    contactFunction,
    isThirdConnection
  } = props

  const [agentHold, setAgentHold] = useState({ status: false, color: 'white' })
  const [contactDuration, setContactDuration] = useState('00:00')

  const connectionFunction = useMemo(() => {
    try {
      if (!isThirdConnection)
        return !!contactFunction ? contactFunction.getActiveInitialConnection() : null
      return !!contactFunction ? contactFunction.getSingleActiveThirdPartyConnection() : null
    }
    catch (err) {
      return null
    }
  }, [contactFunction])

  const handleHold = useCallback(Callbacks.handleHoldGenerator(connectionFunction, setAgentHold, agentHold), [connectionFunction])
  const timerCall = useCallback(Callbacks.timerCallGenerator(connectionFunction, setContactDuration), [connectionFunction])

  useEffect(() => {
    if (!!connectionFunction) {
      const hold = connectionFunction.isOnHold()
      setAgentHold({ status: hold, color: hold ? 'red' : 'white' })
    }
  }, [connectionFunction])

  useEffect(() => {
    if (!!callerPhone) {
      const timer = setInterval(timerCall, 1000)
      return () => clearInterval(timer)
    }
    return () => { }
  }, [callerPhone])

  const display = _.join(_.compact([contactDuration, callerPhone]), '  ')

  return (
    <Box m={1} flexShrink={0}>
      <IconButton aria-label="ivr" style={{ color: _.get(agentHold, 'color', 'white') }} onClick={handleHold} >
        <Box color='icon'><PhonePaused /></Box>
      </IconButton>
      <span>{display}</span>
      <IconButton aria-label="ivr" onClick={() => connectionFunction.destroy()} >
        <Box color='icon'><PhoneDisabled /></Box>
      </IconButton>
      {'|'}
    </Box>
  )

}

// Export
export default React.memo(IVRCall)
