import React, { useCallback, useMemo } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import styles from './styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(styles)

const ButtonCell = ({
  stateProps: { isReadOnly },
  row: { index },
  functionProps: {
    getButtonStyleState,
    getDisabledButtonState,
    getButtonLabel,
    onRowButtonClick,
    ...rest
  },
  ...props
}, context) => {
  const classes = useStyles(props)

  const getClasses = useCallback((i, classe, getStyle) => {
    const type = !!getStyle && getStyle(i)
    switch (type) {
      case 'success':
        return classe.success
      case 'warning':
        return classe.warning
      case 'error':
        return classe.error
      default:
        return classe.error
    }
  }, [])

  const customClasses = useMemo(() => getClasses(index, classes, getButtonStyleState), [index, classes, getButtonStyleState])

  const disabled = !!getDisabledButtonState ? getDisabledButtonState(index) : isReadOnly
  const label = !!getButtonLabel ? getButtonLabel(index) : "Action"
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      height={1}
    >
      <Button
        disabled={disabled}
        size='small'
        variant='outlined'
        classes={{ root: customClasses }}
        onClick={onRowButtonClick}
      >
        {label}
      </Button>
    </Box>
  )
}

export default ButtonCell
