import React from 'react'
import { hooks } from '@front/volcanion'
import UserUtils from '@front/volcanion/utils/user'
import { Collapse } from '@mui/material'

const withContainer = Component => (props) => {
  const [user_id] = hooks.useFieldValues(['user_id'])
  const [user] = hooks.useModel('user', [user_id], {
    single: true,
    populate: ['info', 'auth', 'options', 'vehicle.info.model.brand', 'vehicle.options', 'driver_blacklists'],
    mutator: usr => ({
      ...usr,
      name: UserUtils.getFormatedName(usr?.info),
      vehicle: !!_.get(usr, 'vehicle.vehicle_id') ? {
        vehicle_id: _.get(usr, 'vehicle.vehicle_id'),
        status: _.get(usr, 'vehicle.status'),
        options: _.get(usr, 'vehicle.options'),
        model: _.get(usr, 'vehicle.info.model.name'),
        brand: _.get(usr, 'vehicle.info.model.brand.name'),
        plaque: _.get(usr, 'vehicle.info.plaque')
      } : null,
    })
  })
  const mergedProps = {
    user
  }

  return (
    <Collapse in={!!user}>
      <Component {...mergedProps} />
    </Collapse>
  )
}

export default withContainer
