import React from 'react'
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './styles'
const useStyles = makeStyles(styles)
const Tag = ({ model_name, id, children }) => {
  if (!id) return null
  return (
    <Link href={`/${model_name}/${id}`}>
      {children}
    </Link>
  )
}

export default React.memo(Tag)
