import React, { useCallback } from 'react'
import moment from 'moment'
import { I18n } from '@front/volcanion'


import { DateTimeField, DialogContent, FormDialog, TextField } from '@front/squirtle'
import { Grid } from '@mui/material'

const Popups = ({
  initialValues,
  count_motive,
  onSubmit,
  onCancel,
}) =>
  <FormDialog
    title={I18n.t('suspend.title')}
    name={'suspended'}
    maxWidth='lg'
    formProps={{
      initialValues,
      onSubmit
    }}
    onCancel={onCancel}
  >
    <DialogContent>
      <Grid container spacing={4} rowSpacing={4} direction='column' alignItems={'center'}>
        <Grid item container justifyContent={'center'}>
          <Grid item xs={10}>
            <TextField
              autoFocus
              name={'motive'}
              label={I18n.t('suspend.motive', { min: count_motive, max: 250 })}
              inputProps={{ maxLength: 250 }}
              minRows={2}
              multiline
              required
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs>
          <DateTimeField
            disablePast
            name='suspendedSince'
            dateProps={{
              label: I18n.t('date.start'),
              inputFormat: 'DD/MM/YYYY',
            }}
            timeProps={{
              label: I18n.t('hour.start'),
            }}
          />
        </Grid>
        <Grid item xs>
          <DateTimeField
            disablePast
            name='suspendedUntil'
            dateProps={{
              label: I18n.t('date.end'),
              inputFormat: 'DD/MM/YYYY',
            }}
            timeProps={{
              label: I18n.t('hour.end'),
            }}
            defaultDateTime={useCallback(() => moment().set({ 'hour': 23, 'minute': 59 }), [])}
          />
        </Grid>
      </Grid>
    </DialogContent>
  </FormDialog>

export default Popups
