import React from 'react'
import Component from './component'
import withContainer from './container'

import Form from './Form'
import Header from './Header'
import Row from './Row'
import ButtonGlobalAction from './ButtonGlobal'
import ButtonRowAction from './ButtonRow'

export {
  Component,
  withContainer,
  Form,
  Header,
  Row,
  ButtonGlobalAction,
  ButtonRowAction
}
export default React.memo(withContainer(Component))

