import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'

import { PowerSettingsNew, WbSunny, Brightness3, AccountCircle, Phone, Edit } from '@mui/icons-material'

import Dialogs from './dialogs'

const MenuComponent = ({
  mode,
  user_name,
  telephony_profile_name,
  onSignOut,
  display_telephony,
  handleClick,
  handleClose,
  handleClickDarkMode,
  handleTelephony,
  onConfirmTelephonyPair,
  onOpenPasswordChangePopup,
  open,
  anchorEl,
  ...props }) =>
  <>
    <Dialogs {...props} />
    <IconButton onClick={handleClick} > <AccountCircle sx={{ color: 'white' }} /> </IconButton>
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{ p: 0 }}
      PaperProps={{
        elevation: 6,
        sx: {
          overflow: 'visible',
          mt: 1.5,
          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem sx={{ pt: '11px', pb: '11px' }} divider disabled>
        <AccountCircle sx={{ fontSize: 50 }} />
        <Typography sx={{ ml: 3, fontSize: 'h6.fontSize' }}>
          {user_name}
        </Typography>
      </MenuItem>
      <MenuItem onClick={handleTelephony} sx={{ display: !!display_telephony ? 'flex' : 'none', pt: '11px', pb: '11px' }}>
        <Phone sx={{ mr: 1 }} />
        <Box>
          <Typography >{I18n.t('telephony.label', { count: 1 })}</Typography>
          <Typography color='green'>{telephony_profile_name}</Typography>
        </Box>
      </MenuItem>
      <MenuItem sx={{ pt: '11px', pb: '11px' }} onClick={onOpenPasswordChangePopup}>
        <Edit sx={{ mr: 1 }} />
        <Typography> {I18n.t('header.resetpassword')} </Typography>
      </MenuItem>
      <MenuItem sx={{ pt: '11px', pb: '11px' }} onClick={onSignOut}>
        <PowerSettingsNew sx={{ color: 'red', mr: 1 }} />
        <Typography> {I18n.t('header.logout')} </Typography>
      </MenuItem>
      <MenuItem sx={{ pt: '11px', pb: '11px' }} onClick={handleClickDarkMode}>
        {mode !== 'dark' ? <Brightness3 sx={{ color: 'moon', mr: 1 }} /> : <WbSunny sx={{ color: 'sun', mr: 1 }} />}
        <Typography sx={{ color: mode === 'dark' && 'sun' }}> {I18n.t(mode === 'dark' ? 'mode.active.light' : 'mode.active.dark')} </Typography>
      </MenuItem>
    </Menu>
  </>

export default React.memo(MenuComponent)
