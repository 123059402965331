import React, { useCallback } from 'react'
import { I18n, hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const [password_new, password_confirm] = hooks.useFieldValues(['password_new', 'password_confirm'])

  const [checkOldPassword, setCheckOldPassword] = hooks.useFormState('checkOldPassword')
  const [checkNewPassword, setCheckNewPassword] = hooks.useFormState('checkNewPassword')
  const [checkConfirmPassword, setCheckConfirmPassword] = hooks.useFormState('checkConfirmPassword')

  const handleCheckOldPassword = useCallback(() => setCheckOldPassword(!checkOldPassword), [checkOldPassword, setCheckOldPassword])
  const handleCheckNewPassword = useCallback(() => setCheckNewPassword(!checkNewPassword), [checkNewPassword, setCheckNewPassword])
  const handleCheckConfirmPassword = useCallback(() => setCheckConfirmPassword(!checkConfirmPassword), [checkConfirmPassword, setCheckConfirmPassword])

  const formValidation = useCallback(() => {
    if (!password_confirm || !password_new) return
    else if (password_confirm !== password_new)
      return [{ field: 'password_confirm', message: I18n.t('password.change.error.match') }]
  }, [password_new, password_confirm])

  hooks.useFormValidation(formValidation)

  const mergedProps = {
    password_new,
    password_confirm,
    checkOldPassword,
    checkNewPassword,
    checkConfirmPassword,
    handleCheckOldPassword,
    handleCheckNewPassword,
    handleCheckConfirmPassword
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}

export default withContainer