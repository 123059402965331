class Callbacks {
  static handleVerifyDriverHandler() {
    return function handleVerifyDriver(driver, order) {

      const missing_vehicle_opts = _.map(
        _.differenceBy(_.get(order, 'vehicle_opts', []), _.get(driver, 'vehicle.options', []), 'vehicle_option_id'),
        'name_translated'
      )

      const missing_driver_opts = _.map(
        _.differenceBy(_.get(order, 'driver_opts', []), _.get(driver, 'options', []), 'user_option_id'),
        'name_translated'
      )

      const errors = []

      if (!!driver) {
        const has_luggage = _.get(order, 'has_lugagge')
        const passenger_count = _.get(order, 'order.passenger_count')
        const vehicle_capacity = !!has_luggage ? _.get(driver, 'vehicle.passenger_luggage_capacity') : _.get(driver, 'vehicle.passenger_capacity')

        if (passenger_count > vehicle_capacity)
          errors.push({ message: 'error.dispatch.capacity' })

        if (_.some(driver.blacklisted_customers, customer => order?.do === customer?.user_id))
          errors.push({ message: 'error.dispatch.blacklist.driver' })

        if (_.some(driver.blacklisted_services, service => order?.service === service?.companyservice_id))
          errors.push({ message: 'error.dispatch.blacklist.service' })

        if (!_.get(driver, 'vehicle'))
          errors.push({ message: 'error.dispatch.pairing' })

        if (!_.isEmpty(missing_driver_opts))
          errors.push({
            message: 'error.dispatch.option.driver',
            options: _.join(_.compact(missing_driver_opts), ', ')
          })

        if (!_.isEmpty(missing_vehicle_opts))
          errors.push({
            message: 'error.dispatch.option.vehicle',
            options: _.join(_.compact(missing_vehicle_opts), ', ')
          })
      }

      return errors
    }
  }
}

export default Callbacks
